import classNames from 'classnames'
import Image from 'next/image'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {ImageCarouselStoryblok} from 'types/storyblok-types'
import Link from 'next/link'
import {storyblokEditable} from '@storyblok/react'
import linkTypeChecker from 'util/linkTypeChecker'

type ImageCarouselBlockPropsType = {
  images: ImageCarouselStoryblok['images']
  isRoundedCorners: ImageCarouselStoryblok['rounded_corners_for_images']
  blok: ImageCarouselStoryblok
  imageSize: ImageCarouselStoryblok['image_size']
}

export const ImageCarouselBlock = ({
  images,
  isRoundedCorners,
  blok,
  imageSize,
}: ImageCarouselBlockPropsType) => {
  const carouselItemClass = classNames({
    'rounded-dats md:rounded-dats-xl overflow-hidden': isRoundedCorners,
  })

  const [isInsideOfCarousel, setIsInsideOfCarousel] = useState(false)

  const [pixelsToMove, setPixelsToMove] = useState(0)

  const carouselRef = useRef(null)

  const [scrollbarWidth, setScrollbarWidth] = useState<null | number>(null)

  const imageWidth = useRef(0)

  useMemo(() => {
    switch (imageSize) {
      case 'small':
        imageWidth.current = 100
        break
      case 'medium':
        imageWidth.current = 262

        break
      case 'large':
        imageWidth.current = 380
        break
      default:
        break
    }
  }, [imageSize])

  useEffect(() => {
    const calculateScrollbarWidth =
      window.innerWidth - document.body.clientWidth
    setScrollbarWidth(calculateScrollbarWidth)
  }, [])

  useEffect(() => {
    const ulListWidth = (carouselRef.current as unknown as HTMLUListElement)
      .clientWidth
    const imagesWidth = imageWidth.current * images.length + 40 * images.length

    const isScrollNecesarry = () => {
      if (ulListWidth >= imagesWidth) {
        return false
      } else {
        return true
      }
    }
    ulListWidth + pixelsToMove <= imagesWidth
    // ulListWidth + pixelsToMove >= imagesWidth
    const scrollHandler = (e: any) => {
      if (e.wheelDeltaY > 0 && isScrollNecesarry() === true) {
        setPixelsToMove(oldValue => {
          return oldValue < 0 ? oldValue + 13 : oldValue
        })
      }
      if (
        e.wheelDeltaY < 0 &&
        carouselRef.current &&
        isScrollNecesarry() === true
      ) {
        setPixelsToMove(oldValue => {
          return ulListWidth + -oldValue <= imagesWidth
            ? oldValue - 13
            : oldValue
        })
      }
    }

    const headerElement: HTMLElement = document.querySelector('#fixed-header')!

    if (isInsideOfCarousel) {
      window.addEventListener('wheel', scrollHandler)
      document.body.classList.add('overflow-hidden')
      document.body.style.marginRight = `${scrollbarWidth}px`
      headerElement.style.marginRight = `${scrollbarWidth}px`
    } else {
      window.addEventListener('wheel', () => {
        window.onscroll = null
      })
      document.body.classList.remove('overflow-hidden')
      document.body.style.marginRight = `0px`
      headerElement.style.marginRight = `0px`
    }

    return () => window.removeEventListener('wheel', scrollHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images.length, isInsideOfCarousel])

  const sizeOfImageClass = classNames({
    'w-[100px] h-[100px]': imageSize === 'small',
    'w-[262px] h-[262px]': imageSize === 'medium',
    'w-[380px] h-[380px]': imageSize === 'large',
  })
  const mobileSizeOfImageClass = classNames({
    'w-[30vw] h-[30vw]': imageSize === 'small',
    'w-[55vw] h-[55vw]': imageSize === 'medium',
    'w-[75vw] h-[75vw]': imageSize === 'large',
  })
  const heightOfContainer = classNames({
    'h-[100px]': imageSize === 'small',
    'h-[262px]': imageSize === 'medium',
    'h-[380px]': imageSize === 'large',
  })
  return (
    <>
      <div
        key={blok._uid}
        className={`relative hidden ${heightOfContainer} w-full overflow-hidden md:block`}
        {...storyblokEditable(blok)}
      >
        <div
          className={`relative flex items-center overflow-visible scrollbar-none `}
          onMouseEnter={() => setIsInsideOfCarousel(true)}
          onMouseLeave={() => setIsInsideOfCarousel(false)}
        >
          <ul
            className={`grow-1 relative flex w-full shrink-0 flex-row`}
            ref={carouselRef}
            style={{transform: `translateX(${pixelsToMove}px)`}}
          >
            {images.map(carouselItem => {
              let imageLink = linkTypeChecker(carouselItem.link)

              if (blok.link?.linktype === 'story' && !blok.link.id) {
                imageLink = ''
              }
              return (
                <li
                  className={`relative mr-10 shrink-0 grow-0 ${sizeOfImageClass} ${carouselItemClass}`}
                  key={carouselItem._uid}
                >
                  {imageLink ? (
                    <Link
                      href={linkTypeChecker(carouselItem.link)}
                      target={carouselItem.link?.target}
                    >
                      <Image
                        src={carouselItem.image.filename}
                        alt={
                          carouselItem.image.alt ? carouselItem.image.alt : ''
                        }
                        fill
                        className="object-cover"
                      ></Image>
                    </Link>
                  ) : (
                    <Image
                      src={carouselItem.image.filename}
                      alt={carouselItem.image.alt ? carouselItem.image.alt : ''}
                      fill
                      className="object-cover"
                    ></Image>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="my-5 flex snap-x snap-mandatory gap-5 overflow-x-auto scroll-smooth md:hidden">
        {images.map(image => {
          let imageLink = linkTypeChecker(image.link)

          if (blok.link?.linktype === 'story' && !blok.link.id) {
            imageLink = ''
          }

          return (
            <>
              {imageLink ? (
                <Link
                  target={image.link?.target}
                  href={linkTypeChecker(image.link)}
                  className={`relative flex ${mobileSizeOfImageClass} shrink-0 grow-0 snap-start flex-col pl-4 no-underline`}
                  key={`${image._uid}_mobile`}
                >
                  <Image
                    src={image.image.filename}
                    alt={image.image_alt ? image.image_alt : ''}
                    fill
                    className={`object-cover ${carouselItemClass}`}
                  />
                </Link>
              ) : (
                <div
                  className={`relative flex ${mobileSizeOfImageClass} shrink-0 grow-0  snap-start flex-col pl-4 no-underline`}
                  key={`${image._uid}_mobile`}
                >
                  <Image
                    src={image.image.filename}
                    alt={image.image.alt ? image.image.alt : ''}
                    fill
                    className={`object-cover ${carouselItemClass}`}
                  />
                </div>
              )}
            </>
          )
        })}
      </div>
    </>
  )
}
const ImageCarouselContainer = ({blok}: {blok: ImageCarouselStoryblok}) => {
  return (
    <ImageCarouselBlock
      images={blok.images}
      blok={blok}
      isRoundedCorners={blok.rounded_corners_for_images}
      imageSize={blok.image_size}
    />
  )
}

export default ImageCarouselContainer
